.link {
	color: var(--primary-color);
	text-decoration: none;
	cursor: pointer;
}

.link1 {
	color: var(--blue);
	text-decoration: none;
}

