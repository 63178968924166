.buttonContainer {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 10px;
}

@media screen and (max-width: 500px) {
	.buttonContainer {
		grid-template-columns: auto;
	}
}

.authButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: fit-content;
	padding: 0.5rem 1rem;
	margin-inline: 0.5rem;
	height: 44px;
	background: var(--primary);
	border: 1px solid var(--blue);
	border-radius: 5px;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: var(--blue);
	cursor: pointer;
}

.microsoft {
	background: #2d3748;
	color: #ffffff;
}

.google {
	background: #ffffff;
	color: #2d3748;
}

.linkedIn {
	background: #002d5a;
	color: #ffffff;
}

.btnImgText {
	display: flex;
	align-items: center;
	text-align: center;
	gap: 14px;
}
