@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-toast-max-width: min(80vw, 500px);

  --primary-color: #ffd75d;
  --blue: #2d4764;
  --grey: lightgrey;
  --light-theme-background: white;
  --light-blue-background: #edf5ffaa;
  --black-light: #aaaaaa;
  --darkest-text: black;

  --button-hover-opacity: 0.8;
  --button-hover-scale: 1.10;

  --theme-font: "Nunito", sans-serif;

  --xs: 0px;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;

  --fs-sm: 14px;
  --fs-md: 16px;
  --fs-lg: 18px;
  --fs-xl: 20px;
  --fs-xxl: 22px;

  --container-xxl: 1200px;

  --theme-shadow: 2px 2px 8px rgba(157, 157, 157, 0.24);
}

* {
  font-family: var(--theme-font);
  box-sizing: border-box;
  scroll-behavior: smooth;
}

svg {
  vertical-align: middle !important;
}

#meetingSDKElement {
  top: 59px !important;
  position: absolute;
  padding-top: 1rem;
  padding-left: 1rem;
  left: 0 !important;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.zmwebsdk-makeStyles-videoCustomize-8 {
  z-index: 10000 !important;
}
#transition-popper {
  z-index: 10000000 !important;
}
body {
  margin: 0;
  /* line-height: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf5ffaa !important;
}
::-webkit-scrollbar {
  width: 5px;
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 2px #aaa;
  box-shadow: 0px 1px 2px #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::-webkit-scrollbar-corner {
  background: #ccc;
}

::-webkit-scrollbar-corner:hover {
  background: #aaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-image-preview-img-wrapper > img {
  display: initial;
  max-width: 95%;
}
.ant-slider-track {
  background-color: #2d4764 !important;
}
.ant-slider-handle {
  background-color: #f7f7fc !important;
  border: 0.893617px solid #2d4764 !important;
}
.ant-slider-rail {
  background-color: #d3deea !important;
}

.css-117w1su-MuiStepIcon-text {
  fill: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.ant-select-selector {
  border: 1px solid #e2e2e2 !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
}

.ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important ;
}
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--primary-color) !important;
}
.css-53tbrc-MuiSlider-root {
  padding: 0px !important;
}
.Toastify__toast-container--top-right {
  top: 11em !important;
  max-width: min(90vw, 500px);
  right: 10px !important;
  left: unset !important;
}

.toast-message-layout {
  max-width: min(90vw, 450px);
}

.ant-select-dropdown {
  z-index: 5000 !important;
}
.ant-picker-dropdown {
  z-index: 5000 !important;
}

.normal-bold-text-hack::after {
  content: attr(data-text);
  content: attr(data-text) / "";
  user-select: none;
  pointer-events: none;
  display: block;
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #ffe085 !important;
}
.MuiMenuItem-root:hover {
  background-color: #fff5d8 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffe085 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #fff5d8 !important;
}

.circular-progress-custom {
  display: flex;
  justify-content: center;
}

.circular-progress-custom circle {
  stroke: var(--primary-color);
}

.circular-progress-custom-secoundery {
  display: flex;
  justify-content: center;
}

.circular-progress-custom-secoundery circle {
  stroke: var(--blue);
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.ant-select-selector {
  height: auto !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  display: flex;
  align-items: center;
}

.ant-slider-handle {
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

@media (max-width: 768px) {
  .ant-slider-handle {
    height: 20px;
    width: 20px;
    margin-top: -8px;
  }
}

.custom-tooltip-chartjs {
  background: black;
  padding: 0.5rem 1rem;
  color: white;
  font-family: var(--theme-font);
  font-size: 16px;
  border-radius: 10px;
}

@keyframes shimmer {
  from {
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    transform: translateX(-200%);
  }
  to {
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    transform: translateX(200%);
  }
}

.swiper_uploadCard {
  width: 100% !important;
  padding: 20px !important;
}

.preview-slider {
  /* width: 50% !important; */
  height: 85vh !important;
}

@media (max-width: 600px) {
  .preview-slider {
    width: 200% !important;
    height: 250px !important;
  }
}

.preview-slider .slick-list {
  height: 100%;
}

.preview-slider .slick-list .slick-track {
  height: 100%;
}

.preview-slider .slick-list .slick-track .slick-active div {
  height: 100%;
  position: relative;
}

.preview-slider .slick-slide .preview-image {
  height: 100%;
  object-fit: contain;
}

.preview-slider .slick-slide .preview-img {
  height: 100%;
  object-fit: cover;
}

.main-slider .slick-slide img {
  height: 160px;
  width: 100%;
}
@media (max-width: 500px) {
  .main-slider .slick-slide img {
    height: 120px !important;
  }
}
.main-slider .slick-slide {
  margin: 0 1px;
}

.main-slider .slick-list {
  height: 165px;
}
@media (max-width: 500px) {
  .main-slider .slick-list {
    height: 123px !important;
  }
}

.kvfysmfp {
  height: 100% !important;
}

/* saved offers slider dots fix */
.slick-dots {
  bottom: unset;
}

.jodit-dialog__footer .jodit-ui-button_ok {
  background-color: #0069d9 !important;
  color: #fff !important;
}

/* react-pdf */

.react-pdf__Page__textContent, .react-pdf__Page__annotations {
  display: none;
}

.ant-picker-cell-week{
  display: none;
}
.ant-picker-content th:first-child{
  display: none;
}

