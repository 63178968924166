.closeButton {
  position: relative;
}

.closeButton img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
}

.closeButton:hover {
  cursor: pointer;
}

.closeButton:hover img {
  filter: contrast(200%);
}

.highlighterLine {
  width: 285px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  margin-block: 30px;
  background: radial-gradient(
    96.57% 50% at 50% 50%,
    #ffd75d 0%,
    rgba(255, 215, 93, 0) 61.46%,
    rgba(255, 215, 93, 0) 100%
  );
}

.dialogContent {
  width: 100%;
  max-width: 1051px;
  margin: auto;
  padding: 0 120px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .dialogContent {
    padding: 0 50px 0px;
  }
}

@media screen and (max-width: 767px) {
  .dialogContent {
    padding: 0 20px 0px !important;
  }
}
