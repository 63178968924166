.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2d4764;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  top: 1.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.arrowimg {
  z-index: 999;
}

.popup {
  position: absolute;
  background-color: white;
  padding: 20px;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.toggle-details {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
}

.delete-icon {
  position: absolute;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  width: max-content;
}

.delete-button,
.cancel-button {
  background: none;
  border: none;
  color: #2c3e50;
  font-size: 16px;
  cursor: pointer;
}

.delete-button {
  display: flex;
  align-items: center;
}

.delete {
  font-size: 18px;
  height: 40px;
  width: 40px;
}

.separator {
  margin: 0 10px;
  color: #2c3e50;
}
