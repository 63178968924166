.popupContainer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  display: flex;
  column-gap: 0.5rem;
  overflow-x: hidden;
}

.popupImage {
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .popupImage {
    display: none;
  }
}

.popupImage img {
  object-fit: cover;
  height: 100%;
}

.popupFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 1rem 0.5rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  .popupFormContainer {
    width: 100%;
    margin-inline: 0.5rem;
  }
}

.closeIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.closeIcon:hover {
  filter: contrast(200%);
}

.formHeader {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.fontTitle .fontSubtitle {
  font-family: Nunito;
  text-align: center;
}

.formTitle {
  font-size: 28px;
  font-weight: 700;
  line-height: 38.19px;
  color: #2d4764;
}

.formSubtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 30.01px;
  color: #666666;
  padding-block: 0.5rem;
}

.redDot {
  display: inline-block;
  margin-right: 0.5em;
  height: 0.5em;
  aspect-ratio: 1;
  border-radius: 50%;
  background: red;
  box-shadow: 0px 0px 0px 2px white, 0px 0px 10px red;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-inline: 2rem;
  align-self: stretch;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.primaryButton,
.secondaryButton {
  border-radius: 100px;
  color: #2d4764;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 16px;
}

.primaryButton {
  background: #ffd75d;
}

.secondaryButton {
  border: 1px solid #2d476d;
}

.headerOuterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: "5px solid green";
  width: 100%;
  margin: 35px 25px;
}

.headerInnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.headerTitle {
  color: #2d4764;
  font-family: Nunito;
  font-size: 28px;
  font-weight: 700;
  line-height: 38.19px;
  text-align: center;
}

.checkboxText {
  color: #2d4764;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: left;
}
