.popupContainer {
	position: relative;
	width: 100%;
	max-width: 1200px;
	display: flex;
	column-gap: 0.5rem;
	overflow-x: hidden;
	
}

.popupImage {
	width: 50%;
}

@media screen and (max-width: 1000px) {
	.popupImage {
		display: none;
	}
}

.popupImage img {
	object-fit: cover;
	height: 100%;
}

.popupFormContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
	padding: 1rem 0.5rem;
	padding-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
	.popupFormContainer {
		width: 100%;
		margin-inline: 0.5rem;
	}
}

.closeIcon {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	cursor: pointer;
}

.closeIcon:hover {
	filter: contrast(200%);
}

.formHeader {
	display: flex;
	align-items: center;
	column-gap: 1rem;
	margin-bottom: 1rem;
}

.formTitle,
.formSubtitle {
	text-align: center;
	font-size: 1.5rem;
}

.formTitle {
	color:var(--blue);;
}

.formSubtitle {
	font-size: 1rem;
	color:var(--blue);;
}

.redDot {
	display: inline-block;
  margin-right: 0.5em;
  height: 0.5em;
  aspect-ratio: 1;
	border-radius: 50%;
	background: red;
	box-shadow: 0px 0px 0px 2px white, 0px 0px 10px red;
}
