.partner-consulting {
  display: flex;
  flex-direction: column;
  padding: 60px;
}

.partner-consulting-title {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #2d4764;
}

.partner-consulting-description {
  font-style: normal;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #666666;
}

.partner-consulting-textContainer {
  display: flex;
  flex-direction: column;
  gap: 47px;
  margin-bottom: 40px;
  /* padding-inline: 47px; */
}

.partner-consulting-question {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #666666;
}

.partner-consulting-cards {
  display: flex;
  gap: 9px;
  justify-content: center;
}

.card-container {
  display: flex;
  box-sizing: border-box;
  width: 346px;
  height: 352px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.background {
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  background: #ffce31;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card-container:hover {
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  background: #ffce31;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #2d4764;
}

.card-des-container {
  display: flex;
  justify-content: flex-start;
  height: 21%;
}

.card-subtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #2d4764;
  text-transform: uppercase;
}

.card-description {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #666666;
}

.logoWithCustomeText {
  color: #2d4764;
  display: flex;
  font-weight: 400;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.logoWithCustomeText img {
  width: 37.547px;
  height: 54.318px;
  flex-shrink: 0;
}

.cardImage {
  display: flex;
  width: 157px;
  height: 136px;
}

.makeItFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.arrow_right {
  display: block;
  position: absolute;
  top: 50%;
  right: -10px;
  z-index: 5000;
  transform: translateY(-50%);
  cursor: pointer;
}

.arrow_left {
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
  z-index: 5000;
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 500px) {
  .card-container {
    width: 82%;
    margin: auto;
    margin-right: 32px;
  }
  .partner-consulting {
    overflow-x: hidden;
    padding: 20px;
  }
  .partner-consulting-description {
    font-size: 17px;
  }
  .arrow_left {
    left: 8px;
  }
  .arrow_right {
    right: 1px;
  }
  .card-subtitle {
    font-size: 18px;
  }
  .card-description {
    font-size: 14px;
  }
  .card-title {
    font-size: 24px;
  }
  .logoWithCustomeText {
    font-size: 24px;
  }
}
