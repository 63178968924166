.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  margin-bottom: 20px;
}
.container img {
  margin-left: 10px;
}
.container h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #ffbf1b;
  margin-bottom: 0px !important;
}
.container h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  margin-bottom: 0 !important;
  color: #343434;
}

.container h3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #666666;
}

.cross-icon {
  position: absolute;
  top: 56px;
  right: 30px;
}

.cross-icon:hover {
  cursor: pointer;
}

.img {
  margin-bottom: 20px;
}

.img {
  height: 150px;
  border-radius: 20px;
}

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
}

.inside-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  font-size: 16px;
}

.list-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #343434;
}

.progress-class {
  position: relative;
  height: 40px;
  width: 40px;
}

.border-top {
  background: linear-gradient(226.42deg, #1b4963 8.93%, #09152f 110.98%);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 16px;
}

.inside-progress {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.basis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.ant-modal {
  border-radius: 10px;
  top: unset !important;
}

.modal-main {
  border-radius: 20px;
}

.inside-conatainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 340px;
  padding: 20px 48px;
  gap: 1rem;
  border-radius: 10px;
  align-items: center;
  background-color: #ffd75d;
  text-align: center;
}

.main-container .title {
  font-family: Nunito;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
}

.container-calender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 340px;
  padding: 20px 48px;
  border-radius: 10px;
  border: 1px solid #000000;
}

.container-calender .title {
  color: #2d4764;
  font-size: 20px;
  font-weight: 600;
}

.container-calender .sub-title {
  color: #2d4764;
  font-size: 18px;
  font-weight: 400;
}

.last-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.button {
  background-color: #304864;
  color: #fff;
  border-radius: 10px;
  height: 32px;
  margin-bottom: 20px;
}

.inside-last-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 269.67px;
  gap: 10px;
}

inside-last-container h2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
}

inside-last-container h4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110.5%;
  text-align: center;
}

inside-last-container p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}

.divider {
  height: 1px;
  width: 268px;
  background-color: rgba(251, 218, 97, 1);
}

.suppoer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.suppoer img {
  vertical-align: center;
}

.suppoer :hover {
  cursor: pointer;
}

.ant-modal-body {
  padding: 0px !important;
}

.main-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}

.ant-modal-close {
  display: none;
}

.ant-modal-content {
  border-radius: 20px !important;
}

.suppoer img {
  margin-right: 10px;
}

.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
}

.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
  visibility: hidden !important;
}

.SelectedWeekExample .DayPicker-Day {
  position: relative;
  outline: none;
  padding: 4px !important;
  border-radius: 0px !important;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  z-index: 22;
}

.SelectedWeekExample .DayPicker-Day:hover:not(.DayPicker-Day--selectedRange) {
  background-color: #2d4764 !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 50% !important;
}

.SelectedWeekExample
  .DayPicker-Day:hover::before:not(.DayPicker-Day--selectedRange) {
  content: "";
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #efefef;
  z-index: -1;
}

.DayPicker-Day--selected {
  color: white;
}

.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: #efefef !important;
}

.SelectedWeekExample .DayPicker-Day--hoverRangeStart {
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
  padding-left: 5px;
}

.SelectedWeekExample .DayPicker-Day--hoverRangeEnd {
  border-bottom-right-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding-right: 5px;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background: #2d4764 !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
  padding-left: 5px;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
  border-bottom-right-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding-right: 5px;
}

.DayPicker-NavButton {
  top: 18px !important;
  right: 0 !important;
}

/* .SelectedWeekExample
  .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample
  .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
} */

.DayPicker-Weekdays .DayPicker-WeekdaysRow .DayPicker-Weekday {
  color: #2d4764 !important;
}

.DayPicker-Day--today:not(.DayPicker-Day--selectedRange) {
  color: #4f4f4f !important;
}

.DayPicker-Day--today:not(.DayPicker-Day--selectedRange):hover {
  cursor: pointer;
}

.DayPicker-Caption {
  padding: 0px !important;
}

.DayPicker-Month {
  margin: 1em 0 0 0;
}

/* product tables */

.ant-table-cell {
  color: #2D4764 !important;
}

.ant-table-thead th {
  font-weight: 700 !important;
}

.ant-table-tbody td {
  font-weight: 400;
}

/* final calculation */

.final-offer-table td {
  padding: 0 5px;
  color: #2D4764;
}

.final-offer-table tr:nth-last-child(1) {
  font-weight: 700;
}

.final-offer-table tr td:nth-last-child(1) {
  text-align: right;
}