.no-arrows {
  -moz-appearance: textfield; /* Remove arrows in Firefox */
  appearance: none; /* Remove arrows in Chrome */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
