.tooltip-trigger .tooltip{
    opacity: 0;
}

.tooltip-trigger:hover .tooltip {
    opacity:1;
}

.tooltip-trigger {
    position: relative;
    display: inline;
}

.tooltip-trigger .tooltip{
    width: fit-content;
    background-color: var(--light-theme-background);
    color: var(--blue);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.tooltip-right{
    top: calc(-5px + var(--vertical));
    left: calc(120% + var(--horizontal));
}

.tooltip-left{
    top: calc(-5px + var(--vertical));
    right: calc(120% + var(--horizontal));
}
  
.tooltip-top{
    bottom: calc(120% + var(--horizontal));
    left: calc(0% + var(--horizontal));
}

.tooltip-bottom{
    top: calc(120px + var(--vertical));
    left: calc(0% + var(--horizontal));
}

.tooltip-trigger .tooltip-top::after {
    content: " ";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--light-theme-background) transparent transparent transparent;
}

.tooltip-trigger .tooltip-bottom::after {
    content: " ";
    position: absolute;
    bottom: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--light-theme-background)k transparent;
}

.tooltip-trigger .tooltip-right::after{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--light-theme-background) transparent transparent;
}

.tooltip-trigger .tooltip-left::after{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent  transparent transparent var(--light-theme-background);
}